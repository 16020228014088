<template>
  <div class="flex justify-center w-full">
    <div class="flex flex-col items-center w-4/5 max-w-6xl my-16">
      <h1 class="text-4xl font-black text-center text-gray-800">
        Alguma dúvida?
      </h1>
      <p class="text-lg text-center text-gray-800 font-regular">
        Quer saber melhor como funciona e quais são so preços?
      </p>
      <div class="mt-10">
        <a href="mailto:" class="px-6 py-2 mt-10 font-bold text-white rounded-full bg-brand-main focus:outline-none">
          Nos mande um e-mail
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
